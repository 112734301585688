import { makeStyles } from '@mui/styles';

import { PRIMARY_COLOR, SECONDARY_COLOR } from '@/app/theme';

export const useStyles = makeStyles(() => ({
  '@keyframes gradient': {
    '0%': {
      backgroundPosition: '0% 50%'
    },
    '100%': {
      backgroundPosition: '0% 50%'
    },
    '50%': {
      backgroundPosition: '100% 50%'
    }
  },
  animatedBackground: {
    animation: '$gradient 130s ease infinite',
    background: `linear-gradient(-45deg, ${PRIMARY_COLOR}, ${SECONDARY_COLOR}, ${PRIMARY_COLOR}, ${SECONDARY_COLOR})`,
    backgroundSize: '400% 400%'
  }
}));
