import path from 'path';

import { i18n, Locale } from '@/i18n.config';
import { isLang } from '@/services/url.service';

export const redirectedPathName = (pathName: string, locale: string) => {
  if (!pathName) {
    return '/';
  }

  const pathnameIsMissingLocale = i18n.locales.every(l => !pathName.startsWith(`/${l}/`) && pathName !== `/${l}`);

  if (pathnameIsMissingLocale) {
    if (locale === i18n.defaultLocale) {
      return pathName;
    }

    return `/${locale}${pathName}`;
  } else {
    if (locale === i18n.defaultLocale) {
      const segments = pathName.split('/');
      const isHome = segments.length === 2;
      if (isHome) {
        return '/';
      }

      segments.splice(1, 1);

      return segments.join('/');
    }

    const segments = pathName.split('/');
    segments[1] = locale;

    return segments.join('/');
  }
};

const getContentPath = (dir: string[]) => path.join(`${process.cwd()}/content`, ...dir);

export const getJsonPath = (dir: string[], lang: Locale) => path.join(getContentPath(dir), `${lang}.json`);
export const getMarkdownPath = (dir: string[]) => path.join(getContentPath(dir), '_markdown');

export const getMarkdownFile = (dir: string[]) => {
  const dirs = dir.slice(1);
  const lang = isLang(dirs[0]) ? dirs[0] : i18n.defaultLocale;
  const realPath = isLang(dirs[0]) ? dirs.slice(1) : dirs;

  return getMarkdownPath(realPath) + `/${lang}.md`;
};
