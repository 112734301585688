import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import SvgIcon from '@mui/material/SvgIcon/SvgIcon';

interface Page {
  key: 'videos' | 'livestreaming' | 'tools';
  href: string;
  Icon?: typeof SvgIcon;
}

export const PAGES: Page[] = [
  {
    Icon: SmartDisplayIcon,
    href: '/videos',
    key: 'videos'
  },
  {
    Icon: LiveTvIcon,
    href: '/livestreaming',
    key: 'livestreaming'
  },
  {
    Icon: AutoFixHighIcon,
    href: '/tools',
    key: 'tools'
  }
];
