import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Box/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Card/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/CardActionArea/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/CardContent/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/CardMedia/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Container/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/CssBaseline/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Link/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/styles/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Typography/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/theme.ts");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Icons/LogoSvg.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/XAppBar/XAppBar.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/modules/Amplitude/AmplitudeContext.tsx")