'use client';

import { createTheme } from '@mui/material/styles';
import { Roboto } from 'next/font/google';

import { LinkBehavior } from '../components/MUI/LinkBehaviour';

export const PRIMARY_COLOR = '#fcc100';
export const PRIMARY2_COLOR = '#FCC10079';
export const SECONDARY_COLOR = '#1fbde1';
export const SECONDARY2_COLOR = '#1FBDE188';

const roboto = Roboto({
  display: 'swap',
  subsets: ['latin'],
  weight: ['300', '400', '500', '700']
});

export const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior
      }
    }
  },
  palette: {
    primary: {
      main: PRIMARY_COLOR
    }
  },
  typography: {
    fontFamily: roboto.style.fontFamily,
    h1: {
      fontSize: '3rem',
      fontWeight: 500
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 500
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 500
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 500
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 500
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500
    }
  }
});
