'use client';

import * as React from 'react';
import { FC, MouseEvent, useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useRouter } from 'next/navigation';

import { PAGES } from './PAGES';
import { GlobalDictionary } from '../../lib/i18n/globalDictionary';

interface Props {
  dictionary: GlobalDictionary;
}

export const MobileMenu: FC<Props> = ({ dictionary: { navigation } }) => {
  const router = useRouter();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navigateTo = (url: string) => {
    void router.push(url);
    handleCloseNavMenu();
  };

  return (
    <>
      <IconButton
        aria-controls="menu-appbar"
        aria-haspopup="true"
        aria-label="account of current user"
        color="inherit"
        size="large"
        onClick={handleOpenNavMenu}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorElNav}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
        id="menu-appbar"
        open={Boolean(anchorElNav)}
        sx={{
          display: { md: 'none', xs: 'block' }
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top'
        }}
      >
        {PAGES.map(({ key, href }) => (
          <MenuItem key={href} onClick={() => navigateTo(href)}>
            <Typography textAlign="center">{navigation[key]}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
