import { forwardRef, ReactNode } from 'react';

import { Locale } from '@/i18n.config';

import { Link } from '../Link';

interface Props {
  href: string;
  lang: Locale;
  children: ReactNode;
}

export const LinkBehavior = forwardRef<HTMLAnchorElement, Props>(({ children, ...props }, ref) => (
  <Link ref={ref} {...props}>
    {children}
  </Link>
));

LinkBehavior.displayName = 'LinkBehavior';
