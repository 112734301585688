'use client';

import { FC, SVGProps } from 'react';

import { SECONDARY_COLOR } from '@/app/theme';

import { useSvgHelper } from './useSvgHelper';

interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
  name?: string;
}

export const LogoSvg: FC<Props> = ({ size, color = SECONDARY_COLOR, name, ...props }) => {
  const { getId, getUrl } = useSvgHelper(name ?? 'LogoSvg');

  return (
    <svg height={size ?? 340} viewBox="0 0 126 150" width={size ?? 340} xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient id={getId('a')} x1="100%" x2="0%" y1="22.172%" y2="77.828%">
          <stop offset="0%" stopColor={color} />
          <stop offset="100%" stopColor="#FFC300" />
        </linearGradient>
      </defs>
      <filter height="130%" id={getId('dropshadow')}>
        <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
        <feOffset dx="2" dy="2" result="offsetblur" />
        <feComponentTransfer>
          <feFuncA slope="0.5" type="linear" />
        </feComponentTransfer>
        <feMerge>
          <feMergeNode />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <path
        d="M27.42 78h35.49L72 94H0l25.28-43.808 10.595-18.345 9.096 15.761L27.419 78Zm13.235-54.449L49.39 8.42 98.815 94H81.311L40.655 23.552ZM54.25 0h17.48L126 94h-17.502L54.25 0Z"
        fill={getUrl('a')}
        style={{ filter: getUrl('dropshadow') }}
      />
    </svg>
  );
};
