import { i18n, Locale } from '../i18n.config';

export const isLang = (lang: string): lang is Locale => i18n.locales.includes(lang as Locale);

export const parseUrl = (fullPath: string[]) => {
  const lang: Locale = isLang(fullPath[0]) ? fullPath[0] : i18n.defaultLocale;
  const path = fullPath[0] === lang ? fullPath.slice(1) : fullPath;

  return { lang, path };
};

export const buildUrl = (path: string[], index?: number) => '/' + [...path].splice(0, index ?? 10).join('/');
