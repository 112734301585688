'use client';

import * as React from 'react';
import { FC } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { Locale } from '@/i18n.config';
import { GlobalDictionary } from '@/lib/i18n/globalDictionary';

import { LocaleSwitcher } from './LocaleSwitcher';
import { MobileMenu } from './MobileMenu';
import { PAGES } from './PAGES';
import { useStyles } from './useStyles';
import { LogoSvg } from '../Icons/LogoSvg';

interface Props {
  lang: Locale;
  dictionary: GlobalDictionary;
}

export const XAppBar: FC<Props> = ({ lang, dictionary }) => {
  const classes = useStyles();

  return (
    <header>
      <AppBar className={classes.animatedBackground} position="static">
        <Container component="nav" maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ display: { md: 'flex', xs: 'none' }, mr: 1, mt: 1 }}>
              <LogoSvg name="top-normal" size={48} />
            </Box>
            <Typography
              noWrap
              component={Link}
              href="/"
              lang={lang}
              sx={{
                color: 'inherit',
                display: { md: 'flex', xs: 'none' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                mr: 2,
                textDecoration: 'none'
              }}
              variant="h6"
            >
              {dictionary.title}
            </Typography>

            <Box sx={{ display: { md: 'none', xs: 'flex' }, flexGrow: 1 }}>
              <MobileMenu dictionary={dictionary} />
            </Box>

            <Box sx={{ display: { md: 'none', xs: 'flex' }, mr: 1, mt: 1 }}>
              <LogoSvg name="top-mobile" size={48} />
            </Box>
            <Typography
              noWrap
              component={Link}
              href="/"
              lang={lang}
              sx={{
                color: 'inherit',
                display: { md: 'none', xs: 'flex' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                mr: 2,
                textDecoration: 'none'
              }}
              variant="h5"
            >
              {dictionary.title}
            </Typography>

            <Box sx={{ display: { md: 'flex', xs: 'none' }, flexGrow: 1 }}>
              {PAGES.map(({ key, href, Icon }) => (
                <Link
                  key={href}
                  href={href}
                  lang={lang}
                  sx={{ alignItems: 'center', color: 'inherit', display: 'flex', m: 2 }}
                >
                  {Icon && <Icon color="info" fontSize="inherit" sx={{ mr: 0.5 }} />}
                  {dictionary.navigation[key]}
                </Link>
              ))}
            </Box>

            <Box sx={{ display: 'flex', flexGrow: 0 }}>
              <LocaleSwitcher lang={lang} />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </header>
  );
};
