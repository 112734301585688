import { forwardRef, ReactNode } from 'react';

import { LinkProps } from 'next/dist/client/link';
import NextLink from 'next/link';

import { i18n, Locale } from '../i18n.config';

interface Props extends LinkProps {
  href: string;
  lang: Locale;
  children: ReactNode;
}

export const Link = forwardRef<HTMLAnchorElement, Props>(({ href, lang, children, ...props }, ref) => {
  const isDefaultLang = !lang || lang === i18n.defaultLocale;
  const path = isDefaultLang ? href : `/${lang}${href}`;

  return (
    <NextLink href={path} {...props} ref={ref}>
      {children}
    </NextLink>
  );
});

Link.displayName = 'Link';
