'use client';
import { createContext, FC, PropsWithChildren, useEffect } from 'react';

import { init, track } from '@amplitude/analytics-browser';

import { AMPLITUDE_API_KEY, IS_PRODUCTION } from '@/app.const';

interface IAmplitudeContext {
  trackAmplitudeEvent: (eventName: string, eventProperties: Record<string, any>) => void;
}

export const AmplitudeContext = createContext<IAmplitudeContext>({
  trackAmplitudeEvent: () => {
    throw new Error('AmplitudeContext is not initialized');
  }
});

export const AmplitudeContextProvider: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    if (!IS_PRODUCTION) {
      return;
    }
    init(AMPLITUDE_API_KEY, undefined, {
      defaultTracking: {
        sessions: true
      }
    });
  }, []);

  const trackAmplitudeEvent = (eventName: string, eventProperties: Record<string, any>) => {
    track(eventName, eventProperties);
  };

  const value: IAmplitudeContext = { trackAmplitudeEvent };

  return <AmplitudeContext.Provider value={value}>{children}</AmplitudeContext.Provider>;
};
