'use client';

import { FC, MouseEvent, useState } from 'react';

import { Button } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { i18n, Locale } from '@/i18n.config';

import { redirectedPathName } from '../../lib/i18n/i18n.utils';

interface Props {
  lang: Locale;
}

export const LocaleSwitcher: FC<Props> = ({ lang }) => {
  const pathName = usePathname();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const show = (event: MouseEvent<HTMLButtonElement>) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };
  const close = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        color="inherit"
        id="i18n-locale-switcher"
        sx={{ mr: 1 }}
        onClick={show}
        onMouseOver={show}
      >
        {lang}
      </Button>
      <Menu
        MenuListProps={{ 'aria-labelledby': 'i18n-locale-switcher', onMouseLeave: close }}
        anchorEl={anchorEl}
        id="i18n-locale-switcher-menu"
        open={open}
        onClose={close}
      >
        {i18n.locales.map(locale => (
          <li key={locale}>
            <MenuItem
              component={Link}
              href={redirectedPathName(pathName, locale)}
              sx={{ fontWeight: lang === locale ? 'bold' : 'inherit' }}
              onClick={close}
            >
              {locale}
            </MenuItem>
          </li>
        ))}
      </Menu>
    </>
  );
};
